import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { MeetingRoomBooking, MeetingRoomStatus, RoomBookingSource } from "../models/MeetingRooms";
import { HOUR_TIME_SLOT_HEIGHT, MEETING_ROOM_HEADER_HEIGHT } from "./StructuralComponents";
import Colours from "../../../UI/atoms/Colours";
import { BodySmall, BodyVerySmall, BodyXXSmall } from "../../../UI/atoms/fonts/Body";
import Icon, { IconTypes } from '../../../UI/atoms/icon/Icon';
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../store/ducks/dialog.duck";
import { useTranslation } from "react-i18next";
import moment from "moment";

export function MeetingRoomBookingContentCard(props: Props) {
  const dispatch = useDispatch();
  const {booking, excludeOffset} = props;
  const {t} = useTranslation();

  const onClick = () => {
    dispatch(openDialogWithPayload({
      payload: { booking: booking },
      activeDialog: DialogIdentifiers.EditBookMeetingRoom
    }));
  }

  const hourOffset = useMemo(() => {
    const offset = !!excludeOffset ? 0 : MEETING_ROOM_HEADER_HEIGHT;
    return moment(booking.startDateTime).local().hour() * HOUR_TIME_SLOT_HEIGHT + offset;
  }, [booking, excludeOffset]);

  const minuteOffset = useMemo(() => {

    return (moment(booking.startDateTime).local().minute() / 60) * HOUR_TIME_SLOT_HEIGHT;
  }, [booking]);

  const height = useMemo(() => {
    return (moment(booking.endDateTime).local().diff(moment(booking.startDateTime), 'minutes') / 60) * HOUR_TIME_SLOT_HEIGHT;
  }, [booking]);

  const showDescription = height > 35

  return (
      <Container hourOffset={hourOffset}
                 minuteOffset={minuteOffset}
                 bookingHeight={height}
                 bookingSource={booking.source}
                 onClick={onClick}
                 source={booking.source}
                 status={booking.status ?? MeetingRoomStatus.COMPLETE}>
        <ContentWrapper bookingHeight={height}>
          {booking.bookerName ? <>
            <BodySmall weight={600} className={"meeting__bookerName"} colour={Colours.white}>{booking.bookerName}</BodySmall>
            {showDescription && <BodyXXSmall weight={400}
                                             className={"meeting__description"}
                                             colour={Colours.white}>{booking?.name}</BodyXXSmall>}
          </> : <>
            <BodySmall weight={600} style={{marginBottom: 4}} className={"meeting__bookerName"} colour={Colours.white}>Room Unavailable</BodySmall>
            {showDescription && <BodyXXSmall weight={400}
                                             className={"meeting__description"}
                                             colour={Colours.white}>{t('room-booking-dialog.no-booker-name')}</BodyXXSmall>}
          </>}
          {booking.status === MeetingRoomStatus.PENDING && <Icon className={'meetingIcon'} icon={IconTypes.Time} color={'white'} />}

        </ContentWrapper>
        {getDebugMessage(booking?.status ?? MeetingRoomStatus.COMPLETE, booking.source)}
      </Container>
  )
}

interface Props {
  booking: MeetingRoomBooking;
  excludeOffset?: boolean;
}

function getOutlineColour(status: MeetingRoomStatus, source: RoomBookingSource) {
  if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
    if (status === MeetingRoomStatus.PENDING) {
      // Users booking that can be managed but not yet found in meeting room calendar
      return '#FFD027';
    }
    // In system and matched to outlook
    return '#0057FF';
  }

  if (status === MeetingRoomStatus.PENDING) {
    // Room is linked to outlook. Booking has been made but has not yet been matched to an outlook booking.
    return '#FFD027';
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      // Team today booking, room not linked to Outlook
      return '#3A68BF';
    } else if (source === RoomBookingSource.OUTLOOK) {
      // Outlook booking, room linked to Outlook but no booking found in Team Today
      return '#224FA6';
    } else if (source === RoomBookingSource.EDITED_SYSTEM_LINKED_TO_OUTLOOK) {
      //
      return Colours.orange;
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      // In system and matched to outlook, but shouldn't get here
      return '#174AAC';
    }
  }

  return Colours.darkGrey;
}

function getFillColour(status: MeetingRoomStatus, source: RoomBookingSource) {
  if (source === RoomBookingSource.TEAM_TODAY) {
    return Colours.blue;
  }

  if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
    if (status === MeetingRoomStatus.PENDING) {
      // Users booking that can be managed but not yet found in meeting room calendar
      return Colours.plannerOrange;
    }
    // In system and matched to outlook
    return '#0057FF';
  }

  if (status === MeetingRoomStatus.PENDING) {
    // Room is linked to outlook. Booking has been made but has not yet been matched to an outlook booking.
    return Colours.plannerOrange;
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.OUTLOOK) {
      // Outlook booking, room linked to Outlook but no booking found in Team Today
      return '#174AAC';
    } else if (source === RoomBookingSource.EDITED_SYSTEM_LINKED_TO_OUTLOOK) {
      // In system and matched to outlook, but shouldn't get here
      return Colours.orange;
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      // In system and matched to outlook, but shouldn't get here
      return '#0057FF';
    }
  }

  return Colours.darkGrey;
}

function getTextColour(status: MeetingRoomStatus, source: RoomBookingSource) {
  if (status === MeetingRoomStatus.PENDING) {
    // Users booking that can be managed but not yet found in meeting room calendar
    return '#915120';
  }
  return Colours.white;
}

function getDebugMessage(status: MeetingRoomStatus, source: RoomBookingSource): any {
  if (status === MeetingRoomStatus.PENDING) {
    if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      return (<DebugBox className={'meetingBooking__debugBox'}>
        <BodyVerySmall colour={Colours.darkBlue} weight={600}>Room pending</BodyVerySmall>
        <BodyVerySmall colour={Colours.darkBlue}>Please allow 1–2 minutes for Outlook to confirm your booking before refreshing. </BodyVerySmall>
      </DebugBox>);
    }
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      return (<DebugBox className={'meetingBooking__debugBox'}>
        <BodyVerySmall colour={Colours.darkBlue} weight={600}>Room booking successful</BodyVerySmall>
      </DebugBox>);
    } else if (source === RoomBookingSource.OUTLOOK) {
      return (<DebugBox className={'meetingBooking__debugBox'}>
        <BodyVerySmall colour={Colours.darkBlue} weight={600}>Room booked via Outlook</BodyVerySmall>
        <BodyVerySmall colour={Colours.darkBlue}>Check Outlook to see who has booked the room.</BodyVerySmall>
      </DebugBox>);
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      return (<DebugBox className={'meetingBooking__debugBox'}>
        <BodyVerySmall colour={Colours.darkBlue} weight={600}>Outlook room booking successful</BodyVerySmall>
      </DebugBox>);
    } else if (source === RoomBookingSource.EDITED_SYSTEM_LINKED_TO_OUTLOOK) {
      return (<DebugBox className={'meetingBooking__debugBox'}>
        <BodyVerySmall colour={Colours.darkBlue} weight={600}>Room booked via Team Today but edited in Outlook</BodyVerySmall>
        <BodyVerySmall colour={Colours.darkBlue}>Matched booking on Room name and time.</BodyVerySmall>
        <BodyVerySmall colour={Colours.darkBlue}>Bookings edited in Outlook Desktop App loose their connection between Team Today and Outlook.</BodyVerySmall>
      </DebugBox>);
    }
  }

  return null
}

const Container = styled.div<any>`
  position: absolute;
  top: ${props => props.hourOffset + props.minuteOffset}px;
  left: 2%;
  height: ${props => props.bookingHeight}px;
  width: 96%;
  background-color: ${props => getFillColour(props.status, props.source)};
  border-radius: 4px;
  border: 1px solid #064CD2;
  border-color: ${props => getOutlineColour(props.status, props.source)};
  padding: 6px 16px;
  
  .meetingIcon {
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: ${props => getTextColour(props.status, props.source)};
  }
  .meeting__description {
    color: ${props => getTextColour(props.status, props.source)};
  }
  .meeting__bookerName {
    max-width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 16px;
    color: ${props => getTextColour(props.status, props.source)};
  }
  &:hover {
    .meetingBooking__debugBox {
      display: block !important;
    }
  }
`

const ContentWrapper = styled.div<any>`
  height: ${props => props.bookingHeight - 12}px;
  overflow: hidden;
  &:hover {
    .meetingBooking__debugBox {
      display: block !important;
    }
  }
`

const DebugBox = styled.div`
  display: none;
  background-color: white;
  border-radius: 12px;
  min-width: 200px;
  max-width: 300px;
  position: absolute;
  padding: 12px;
  top: 10px;
  left: 75%;
  z-index: 99999999;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  ul {
    margin-left: 24px;
  }
`
