import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../UI/organisms/layout/Layout';
import Calendar from '../../UI/molecules/calendar/Calendar';
import './styles/team-movements.scss';
import { AddTeamMemberButton } from "../../UI/atoms/buttons/AddTeamMemberButton";
import { redirectTo, selectCurrentUser } from "../../../store/ducks/auth.duck";
import { Column, Row } from '../../UI/atoms/StructuralLayout';
import { selectCalendarDate, setCalendarDate } from "../../../store/ducks/dashboard.duck";
import { VerticalSpacer } from "../../UI/atoms/VerticalSpacer";
import { useParams } from "react-router";
import { requestTeamWeeklyMovements } from "../../../services/Movements";
import { TeamWithMovements } from "../../../models/movements.models";
import { TeamMovementResponse } from "../../../models/api.models";
import { deepLinkTo, isInTeamsStoreApp } from "../../../utils/TeamsUtils";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { teamsTabs, uiUrl } from "../../../services/EnvironmentVariables";
import { TeamWallChart } from "../../UI/organisms/movements-wallchart/TeamWallChart";
import { CurrentUsersWeeklyWhereabouts } from "../../UI/organisms/CurrentUsersWeeklyWhereabouts";
import WallChartHeader from "../../UI/organisms/movements-wallchart/components/WallChartHeader/WallChartHeader";
import { PageTitle } from "../../UI/atoms/PageTitle";

export default function SingleTeamViewPage() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const teamId = useMemo(() => params.teamId, [params]);
  const calendarDate = useSelector(selectCalendarDate);
  const current = useSelector(selectCurrentUser);
  const [teamMovements, setTeamMovements] = useState<TeamWithMovements | undefined>(undefined);

  useEffect(() => {
    if (!teamId || !calendarDate) {
      return;
    }
    requestTeamWeeklyMovements(teamId, calendarDate)
      .then((resp: TeamMovementResponse) => {
        console.log(resp);
        return new TeamWithMovements(resp.teamEntity, true, true, resp.teamWhereabouts, resp.userDesks, resp.restricted, resp.hasWeekNotes);
      })
      .then(setTeamMovements)
  }, [teamId, calendarDate]);

  const onGoBackClick = () => {
    if (isInTeamsStoreApp()) {
      deepLinkTo(teamsTabs.company, `${uiUrl}/whereabouts/company/all`);
    } else {
      dispatch(redirectTo('/whereabouts/company/all'));
    }
  }

  return <>
    <Layout>
      <Row className="new-teamMovements">
        <Column className="teamMovementsContent" style={{maxWidth: current?.companyEntity.sevenDayWhereaboutsWeekEnabled ? 1100 : 1000}}>
          <GoBackButton onClick={onGoBackClick} text="back-to-company-view" url="/whereabouts/company/all" />
          <PageTitle text={teamMovements?.team.name ?? ''} showTodayButton={true} style={{marginTop: 0}} headlineLarge={true} />
          <WallChartHeader/>
          {!!teamMovements ? <TeamWallChart movementsViewObject={teamMovements}
                                            disableAccordian={true}
                                            showHeader={false}
                                            showFooter={true}
                                            hasWeekNotesEnabled={current?.companyEntity.weeklyNotesEnabled}
                                            enabledSetMovements={true}/> : <></>}
          <CurrentUsersWeeklyWhereabouts style={{marginBottom: 24}} />
        </Column>
        <VerticalSpacer />
        <Column className="teamMovementsSidebar">
          <Calendar initialDate={calendarDate} onDateSelect={(date) => dispatch(setCalendarDate(date))}/>
          <AddTeamMember/>
        </Column>
      </Row>
    </Layout>
  </>
}

function AddTeamMember(props: { className?: string }) {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <Column className={`addTeamMember ${props.className ?? ''}`.trim()}>
      <img src="/assets/images/man-fishing.svg" alt="Man fishing" aria-hidden="true"/>
      <AddTeamMemberButton team={currentUser?.teamEntity}/>
    </Column>
  )
}


