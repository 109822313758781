import React, { useEffect, useState } from 'react';
import {
  addNewTeamMember,
  selectAddMemberProcessingState,
  setError
} from "../../../../../store/ducks/addMemberDialog.duck";
import { useDispatch, useSelector } from "react-redux";
import { BodyError, BodyRegular } from "../../../atoms/fonts/Body";
import TextField from "../../../atoms/TextField";
import { Button } from "../../../atoms/buttons/Button";
import { LoadingSpinner } from "../../../atoms/LoadingSpinner";
import { TeamWithCount } from "../../../../../models/team.models";
import TeamDropDownList from "../../team-drop-down-list/TeamDropDownList";
import styled from "styled-components/macro";
import { selectAllTeams } from "../../../../../store/ducks/config.duck";
import Colours from "../../../atoms/Colours";

interface Props {
  team?: TeamWithCount;
  hideImages: boolean;
  message?: string;
}

export function AddTeamMemberInput(props: Props) {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectAddMemberProcessingState);
  const teams = useSelector(selectAllTeams);

  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [team, setTeam] = useState<TeamWithCount | undefined>();

  const addNewMember = () => {
    if (!firstname) {
      dispatch(setError({message: 'First name required'}));
      return;
    }
    if (!lastname) {
      dispatch(setError({message: 'Last name required'}));
      return;
    }
    if (!email) {
      dispatch(setError({message: 'Email required'}));
      return;
    }
    if (!team) {
      dispatch(setError({message: 'Team required'}));
      return;
    }
    dispatch(addNewTeamMember({firstname, lastname, email, teamId: team.id}));
  };

  useEffect(() => {
    if (!team) {
      setTeam(props.team);
    }
  }, [team, props]);

  return (
    <React.Fragment>
      {dialogState.processing && <LoadingSpinner fullScreen={true} />}

      {props.message && <BodyRegular style={{marginTop: 8, marginBottom: 12}}>{props.message}</BodyRegular>}

      <InputList onSubmit={(e) => e.preventDefault()}>
        <TextField fullWidth={true}
                   dataTest="firstNameInput"
                   label="textinput.firstname"
                   value={firstname}
                   onChange={(val: string) => setFirstname(val)}/>
        <TextField fullWidth={true}
                   dataTest="lastNameInput"
                   label="textinput.lastname" value={lastname}
                   onChange={(val: string) => setLastname(val)}/>
        <TeamDropDownList teams={teams}
                          selectedTeamId={team?.id}
                          label={"Select a team"}
                          onSelect={(teamId) => setTeam(teams.find((teamEntity) => teamEntity.id === teamId))} />
        <TextField fullWidth={true} dataTest="emailInput" label="login.email-address" value={email} onChange={(val: string) => setEmail(val)}/>

        <BodyError>{dialogState.error}</BodyError>

        <Button fullWidth={true}
                size="large"
                dataTest="submitAddMember"
                buttonType="submit"
                text="button.send-invite"
                onClick={addNewMember}/>
      </InputList>
    </React.Fragment>
  )
}

const InputList = styled.form`
  .teamDropDownList__label, .switch__label, .textField__label {
    color: ${Colours.black} !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`
