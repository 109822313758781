import React, { useState } from 'react';
import { ConfigurationSection } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../../../../store/ducks/auth.duck";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";
import { GlobalReminder } from "../../../../models/company.models";
import { updateCompanySetting } from "../../../../services/CompanyService";
import { OutlineRadioButton } from "../../molecules/OutlineRadioButton";

export function GlobalEmailRemindersSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const onChange = async (val: GlobalReminder) => {
    if (user?.companyEntity.globalReminder === val) {
      return;
    }
    setIsLoading(true);
    if (user?.companyEntity) {
      try {
        await updateCompanySetting('GLOBAL_REMINDER', val);
        dispatch(setUser({
          ...user,
          companyEntity: {
            ...user.companyEntity,
            globalReminder: val,
          }
        }))
      } finally {
        setIsLoading(false);
      }
    } else {
      dispatch(warningNotification('No company attached to user'));
    }
  }

  const enabled = true;
  const isFreeAccount = (user?.companyEntity.userLimit ?? 0) <= 10;
  const globalReminder = isFreeAccount ? GlobalReminder.USERS_CHOICE : (user?.companyEntity.globalReminder ?? GlobalReminder.USERS_CHOICE);

  if (enabled) {
    return (
      <ConfigurationSection>
        <BodyLarge colour={Colours.blue}>{t('settings.global-email-setting.title')}</BodyLarge>
        <BodyRegular style={{marginBottom: 16}}>{t('settings.global-email-setting.description')}</BodyRegular>

        <OutlineRadioButton onClick={() => onChange(GlobalReminder.ALL_USERS)}
                            text={'settings.global-email-setting.all-users'}
                            isSelected={globalReminder === GlobalReminder.ALL_USERS}
                            style={{padding: '16px 24px'}}
                            disabled={isLoading || isFreeAccount} />

        <OutlineRadioButton onClick={() => onChange(GlobalReminder.USERS_CHOICE)}
                            text={'settings.global-email-setting.users-choice'}
                            isSelected={globalReminder === GlobalReminder.USERS_CHOICE}
                            style={{padding: '16px 24px'}}
                            disabled={isLoading} />

        <OutlineRadioButton onClick={() => onChange(GlobalReminder.NO_USERS)}
                            text={'settings.global-email-setting.no-users'}
                            isSelected={globalReminder === GlobalReminder.NO_USERS}
                            style={{padding: '16px 24px'}}
                            disabled={isLoading || isFreeAccount} />

      </ConfigurationSection>
    )
  } else {
    return null;
  }
}
