import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { BodyRegular } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";

export function OutlineRadioButton(props: Props) {
  const {text, isSelected, onClick, disabled} = props;
  const {t} = useTranslation();

  const onButtonClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  }

  return (
    <ButtonContainer onClick={onButtonClick} isSelected={isSelected} isDisabled={disabled} style={props?.style ?? {}}>
      <Row>
        <Radio isSelected={isSelected}>
          {isSelected && <RadioCircle isSelected={isSelected} />}
        </Radio>
        <BodyRegular weight={600}>{t(text)}</BodyRegular>
      </Row>
      {props.children && {...props.children}}
    </ButtonContainer>
  )
}

interface Props {
  text: string;
  isSelected: boolean;
  onClick: () => any;
  children?: any;
  disabled?: boolean;
  style?: any;
}

const ButtonContainer = styled.div<any>`
  padding: 24px;
  border-radius: 8px;
  border: ${props => props.isSelected ? `solid 1px ${Colours.blue}` : `solid 1px ${Colours.mildGrey}`};
  background-color: ${props => props.isDisabled ? Colours.veryLightGrey : Colours.white};
  margin-bottom: 16px;
  cursor: ${props => props.isDisabled ? 'unset' : 'pointer'};
  width: 100%;
  user-select: none;
  * {
    user-select: none;
  }
`

const Row = styled.div<any>`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
`

const Radio = styled.div<any>`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 24px;
  border: ${props => props.isSelected ? `solid 2px ${Colours.blue}` : `solid 1px ${Colours.mildGrey}`};
  position: relative;
`
const RadioCircle = styled.div<any>`
  width: 12px;
  height: 12px;
  top: ${props => props.isSelected ? '8px' : '9px'};
  left: ${props => props.isSelected ? '8px' : '9px'};
  border-radius: 100%;
  background-color: ${props => props.isSelected ? Colours.blue : Colours.mildGrey};
  position: absolute;
`
