import React, { useMemo } from 'react';
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { selectAllMeetingRooms } from "../../settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import Colours from "../../../UI/atoms/Colours";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import moment from "moment";
import { PRETTY_DATE_FORMAT } from "../../../../utils/DateUtils";
import { EnrichedMeetingRoomBooking, RoomBookingSource } from "../models/MeetingRooms";
import { Column } from "../../../UI/atoms/StructuralLayout";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import { findMeetingRoomInMeetingRooms } from "../models/MeetingRoomHelper";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../store/ducks/dialog.duck";
import { MeetingRoomSourceIcon } from "./MeetingRoomSourceIcon";
import { MeetingRoomBookingSourcePill } from "./MeetingRoomBookingSourcePill";

export function MeetingRoomBookingList(props: Props) {
  const {groupedBookings} = props;
  const dispatch = useDispatch();
  const meetingRooms = useSelector(selectAllMeetingRooms);

  const dates = useMemo(() => {
    return Object.keys(groupedBookings).sort();
  }, [groupedBookings])

  const onBookingClick = (booking: EnrichedMeetingRoomBooking) => {
    dispatch(openDialogWithPayload({
      payload: { booking: booking },
      activeDialog: DialogIdentifiers.EditBookMeetingRoom
    }));
  }

  return (
    <>
      {dates.map((date: string, key) => (
        <React.Fragment key={key}>
          <HeaderContentCard>
            <HeadlineSmall>{moment(date).format(PRETTY_DATE_FORMAT)}</HeadlineSmall>
          </HeaderContentCard>
          {groupedBookings[date].map((booking: EnrichedMeetingRoomBooking, keyy: number) => (<MeetingRoomContentCard key={keyy} onClick={() => onBookingClick(booking)}>
            <Row>
              <Column style={{marginRight: 16}}>
                <UserAvatarAndName colour={'darkBlue'}
                                   hideName={true}
                                   firstName={booking.firstName}
                                   lastName={booking.lastName} />
              </Column>
              <Column>
                <BodyLarge weight={600}><MeetingRoomSourceIcon isOutlook={booking.source === RoomBookingSource.OUTLOOK || booking.source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK || booking.source === RoomBookingSource.EDITED_SYSTEM_LINKED_TO_OUTLOOK} />{booking.name}</BodyLarge>
                <BodyRegular>{findMeetingRoomInMeetingRooms(meetingRooms, booking.teamTodayMeetingRoomId)?.name ?? 'Not Found'}</BodyRegular>
                <BodyRegular>{moment(booking.startDateTime).format('Do MMMM')} - {moment(booking.startDateTime).format('h:mm a')} - {moment(booking.endDateTime).format('h:mm a')}</BodyRegular>
              </Column>
              <Column style={{marginLeft: 'auto'}}>
                <MeetingRoomBookingSourcePill source={booking.source} />
              </Column>
            </Row>
          </MeetingRoomContentCard>))}
        </React.Fragment>
      ))}
    </>
  )
}

interface Props {
  groupedBookings: any;
}

const Row = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`

const MeetingRoomContentCard = styled<any>(FlatContentCard)`
  display: flex;
  margin-bottom: 16px;
  transition: background-color .1s ease-in-out, color .1s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${Colours.darkBlue};
    * {
      color: white;
    }
    .userAvatarAndName__avatar {
      background-color: white !important;
      color: ${Colours.darkBlue} !important;
    }
  }
`

const HeaderContentCard = styled<any>(FlatContentCard)`
  margin-bottom: 16px;
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
`
