import React, { useState } from 'react';
import TextField from "../../../../../UI/atoms/TextField";
import TeamDropDownList from "../../../../../UI/organisms/team-drop-down-list/TeamDropDownList";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTeams } from "../../../../../../store/ducks/config.duck";
import { addNewUser, selectError, selectIsLoading } from "../ducks/add-users.duck";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { selectNumberOfRemainingUsers } from "../../../../../../store/ducks/payments.duck";
import { isInTeams } from "../../../../../../utils/TeamsUtils";
import { BodyError, BodyLarge } from "../../../../../UI/atoms/fonts/Body";
import { Trans, useTranslation } from "react-i18next";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import Colours from "../../../../../UI/atoms/Colours";

export function AddNewUserForm(props: Props) {
  const dispatch = useDispatch()
  const teams = useSelector(selectAllTeams);
  const isLoading = useSelector(selectIsLoading);
  const remainingSpaces = useSelector(selectNumberOfRemainingUsers);
  const error = useSelector(selectError);
  const {t} = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [teamId, setTeamId] = useState('');

  const addNewMember = () => {
    if (isLoading) {
      return;
    }
    setFirstNameError('')
    setLastNameError('')
    setEmailError('')
    if (!firstName) {
      setFirstNameError('Required');
    }
    if (!lastName) {
      setLastNameError('Required');
    }
    if (!email) {
      setEmailError('Required');
    }
    if (!!firstNameError || !!lastNameError || !!emailError) {
      return;
    }

    dispatch(addNewUser({
      firstname: firstName,
      lastname: lastName,
      emailAddress: email,
      teamId: teamId,
    }))
  }

  if (remainingSpaces === 0) {
    return <>
      {isInTeams() ? <>
        <BodyLarge style={{marginBottom: 24}}>{t('settings.account-full-in-teams')}</BodyLarge>
      </> : <>
        <BodyLarge style={{marginBottom: 24}}><Trans i18nKey={'settings.account-full-normal'} /></BodyLarge>

        <div style={{marginBottom: 16}}>
          <OutlineButton dataTest="upgradeButton"
                         size="large"
                         text="button.upgrade-my-account"
                         link={"/manage/payments"}
                         fullWidth={true}/>
        </div>
      </>}
    </>
  }

  return (
    <InputList onSubmit={(e) => e.preventDefault()} autoComplete="off">
      <input hidden={true} style={{display: 'none'}} autoComplete="false" />
      {error && <BodyError>{error}</BodyError>}
      <TextField fullWidth={true}
                 disabled={isLoading}
                 dataTest="firstNameInput"
                 label="textinput.firstname"
                 error={firstNameError}
                 value={firstName}
                 onChange={(val: string) => setFirstName(val)}/>
      <TextField fullWidth={true}
                 disabled={isLoading}
                 dataTest="lastNameInput"
                 label="textinput.lastname"
                 error={lastNameError}
                 value={lastName}
                 onChange={(val: string) => setLastName(val)}/>

      <TeamDropDownList teams={teams}
                        disabled={isLoading}
                        selectedTeamId={teamId}
                        label={"Select a team"}
                        onSelect={(teamId) => setTeamId(teamId)} />
      <TextField fullWidth={true}
                 disabled={isLoading}
                 dataTest="emailInput"
                 label="login.email-address"
                 value={email}
                 error={emailError}
                 onChange={(val: string) => setEmail(val)}/>

      <PrimaryButton fullWidth={true}
                     size="large"
                     dataTest="submitAddMember"
                     buttonType="submit"
                     text="button.send-invite"
                     disabled={isLoading}
                     click={addNewMember}/>
    </InputList>
  )
}

interface Props {
}

const InputList = styled.form`
  .teamDropDownList__label, .switch__label, .textField__label {
    color: ${Colours.black} !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`
