import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from "moment";
import { Column, SpaceBetweenRow } from "../../../UI/atoms/StructuralLayout";
import { BodyRegular } from '../../../UI/atoms/fonts/Body';
import Colours from "../../../UI/atoms/Colours";
import { Period, toSideOfDay } from '../../../../models/movements.models';
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { useSelector } from "react-redux";
import { selectConfig, selectHasGlobalConfigLoaded } from "../../../../store/ducks/config.duck";
import { fetchUsersHolidayInfoV2, getEstimatedWorkDaysBooked } from "../../../../services/HolidayService";
import { TrackJS } from "trackjs";
import { UserHolidayInfoResponse } from "../models/Holidays.model";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import { SimpleSpinner } from "../../../UI/atoms/SimpleSpinner";

export function HolidayBreakdownSummary(props: Props) {
  const {fromDate, toDate, userId, startDatePeriod, endDatePeriod} = props;
  const calendarDate = useSelector(selectCalendarDate);
  const config = useSelector(selectConfig);
  const isConfigLoading = useSelector(selectHasGlobalConfigLoaded);
  const [usersHolidayInfo, setUsersHolidayInfo] = useState<UserHolidayInfoResponse | undefined>(undefined);
  const [dayCount, setDayCount] = useState(0);
  const [dayCountLoading, setDayCountLoading] = useState(false);

  const holidayStartDate = useMemo(() => {
    if (fromDate) {
      return config.getHolidayYearStartDate(fromDate)
    }
    else if (calendarDate) {
      return config.getHolidayYearStartDate(calendarDate);
    }
    else {
      return config.getHolidayYearStartDate(moment());
    }
  }, [calendarDate, config, fromDate]);
  const holidayStartDateString = holidayStartDate?.format(DATE_FORMAT)

  const holidayEndDate = useMemo(() => {
    if (fromDate) return config.getHolidayYearEndDate(fromDate)
    else if (calendarDate) return config.getHolidayYearEndDate(calendarDate);
    else config.getHolidayYearEndDate(moment());
  }, [calendarDate, config, fromDate]);

  useEffect(() => {
    if (holidayStartDateString && userId && isConfigLoading) {
      fetchUsersHolidayInfoV2(holidayStartDateString, userId)
        .then(setUsersHolidayInfo)
        .catch((err) => {
          setUsersHolidayInfo(undefined);
          TrackJS?.track(err);
        })
    }
  }, [holidayStartDateString, userId, isConfigLoading]);

  useEffect(() => {
    if (fromDate && toDate && userId && calendarDate) {
      setDayCountLoading(true)
      getEstimatedWorkDaysBooked({
        userId: userId,
        dateFrom: fromDate.format(DATE_FORMAT),
        dateTo: toDate.format(DATE_FORMAT),
        firstDaySideOfDay: toSideOfDay(startDatePeriod),
        lastDaySideOfDay: toSideOfDay(endDatePeriod),
      }).then(response => {
        setDayCount(response.daysUsed);
      })
      .finally(() => setDayCountLoading(false))
    }
  }, [calendarDate, endDatePeriod, fromDate, startDatePeriod, toDate, userId]);

  const daysLeft = useMemo(() => {
    if (!usersHolidayInfo) return '??';
    return usersHolidayInfo.holidayAllowance - usersHolidayInfo.holidaysTaken - dayCount;
  }, [usersHolidayInfo, dayCount]);

  return (
    <Column style={props.style ?? {}}>
      <SpaceBetweenRow style={{borderBottom: `1px solid ${Colours.mildGrey}`}}>
        <BodyRegular weight={400} colour={Colours.black}>Total work days booked</BodyRegular>
        <BodyRegular weight={600} colour={Colours.black}>
          {dayCountLoading ? <SimpleSpinner size={16} /> : <>{dayCount} days</>}
        </BodyRegular>
      </SpaceBetweenRow>

      {config.holidayAllowance && <SpaceBetweenRow>
        <BodyRegular weight={400} colour={Colours.black}>Total holidays left for {holidayStartDate?.year()} - {holidayEndDate?.year()}</BodyRegular>
        <BodyRegular weight={600} colour={Colours.black}>{daysLeft} days</BodyRegular>
      </SpaceBetweenRow>}
    </Column>
  )
}

interface Props {
  fromDate?: Moment
  toDate?: Moment;
  style?: any;
  userId?: string;
  startDatePeriod: Period;
  endDatePeriod: Period;
}
