import React from 'react';
import { HeadlineLarge, HeadlineMedium } from "./fonts/Headline";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { tablet } from "./MediaQueries";
import { BodyRegular } from "./fonts/Body";
import Colours from "./Colours";
import { TodayButton } from "../molecules/TodayButton";

export function PageTitle(props: Props) {
  const {text} = props;
  const {t} = useTranslation();
  return (
    <Wrapper className={'pageTitle'} style={props?.style ?? {}} includeMobileGuttres={!!props.includeMobileGutters}>
      {props.headlineLarge ? <HeadlineLarge>{!!props.overrideText ? props.overrideText : t(text)}</HeadlineLarge> :
        <HeadlineMedium>{!!props.overrideText ? props.overrideText : t(text)}</HeadlineMedium>}

      {props.subText && <BodyRegular style={{lineHeight: 'unset', marginLeft: 16}} weight={400} colour={Colours.darkGrey}>{props.subText}</BodyRegular>}
      {props.showTodayButton && <TodayButton />}
    </Wrapper>
  )
}

interface Props {
  text: string;
  subText?: string;
  style?: any;
  includeMobileGutters?: boolean;
  overrideText?: string;
  showTodayButton?: boolean;
  headlineLarge?: boolean
}

const Wrapper = styled.div<any>`
  width: 100%;
  text-align: center;
  margin: ${props => props.includeMobileGutters ? '24px 16px 4px 16px' : '24px 0 4px 0'};
  display: flex;
  align-items: flex-end;
  h1 {
    font-size: 30px;
  }
  @media (${tablet}) {
    text-align: left;
    h1 {
      font-size: 44px;
    }
  }
  button {
    margin-left: auto;
    margin-right: 16px;
    width: 80px;
    @media (${tablet}) {
      width: 100px;
    }
  }
`
