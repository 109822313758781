import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import {
  bookHolidayV2,
  deleteHolidayV2,
  fetchUsersHolidayInfoV2,
  getTeamHolidays
} from "../../services/HolidayService";
import { HolidayBookingRequest, HolidayDay, HolidayGrouping } from "../../components/pages/holidays-v2/models/Holidays.model";
import moment, { Moment } from "moment";
import { failureNotification, warningNotification } from "./notification.duck";
import { DATE_FORMAT, getListOfDaysBetweenInclusive } from "../../utils/DateUtils";
import { Period } from "../../models/movements.models";
import { selectCalendarDate } from "./dashboard.duck";
import { CompanyHoliday } from "../../services/CompanyHolidayService";

export interface HolidaysV2State {
  usersHolidayBookings: HolidayDay[];
  usersHolidayGroupings: HolidayGrouping[];
  usersHolidayAllowance: number;
  usersHolidaysTaken: number;
  companyHolidays: CompanyHoliday[];
  loading: boolean;
  teamHolidays: any[];
  selectedTeamIds: string[];
  selectedWorkGroupIds: number[];
  usersFirstName?: string;
  usersLastName?: string;
  usersTeamName?: string;
  usersUserId?: string;
}

export const initialHolidaysV2State: HolidaysV2State = {
  usersHolidayBookings: [],
  usersHolidayGroupings: [],
  companyHolidays: [],
  usersHolidayAllowance: 0,
  usersHolidaysTaken: 0,
  loading: false,
  teamHolidays: [],
  selectedTeamIds: [],
  selectedWorkGroupIds: [],
}

export const bookHoliday: any = createAsyncThunk(
  'holidaysV2/bookHoliday',
  async (holidayRequest: {fromDate?: Moment, fromDatePeriod: Period, toDate?: Moment, toDatePeriod: Period, userId?: string, originalFromDate?: Moment, originalToDate?: Moment}, thunkAPI) => {
    const {fromDate, fromDatePeriod, toDate, toDatePeriod, userId, originalFromDate, originalToDate} = holidayRequest;
    if (!fromDate) {
      thunkAPI.dispatch(warningNotification('Start date required'));
      TrackJS?.track('Missing start date for holiday booking');
      return;
    }
    if (!toDate) {
      thunkAPI.dispatch(warningNotification('End date required'));
      TrackJS?.track('Missing end date for holiday booking');
      return;
    }
    if (!userId) {
      thunkAPI.dispatch(warningNotification('Failed to find user'));
      TrackJS?.track('Failed to find user to book holiday');
      return;
    }

    const dates = getListOfDaysBetweenInclusive(fromDate, toDate);
    const originalDates = getListOfDaysBetweenInclusive(originalFromDate, originalToDate).map(date => date.format(DATE_FORMAT));

    // Default all to AllDay, then update first and last after
    let datesToBookList = dates.map(date => new HolidayBookingRequest(date, userId, Period.AllDay));
    if (fromDatePeriod === Period.AM) datesToBookList[0].pm = false;
    else if (fromDatePeriod === Period.PM) datesToBookList[0].am = false;

    if (toDatePeriod === Period.AM) datesToBookList[datesToBookList.length - 1].pm = false;
    else if (toDatePeriod === Period.PM) datesToBookList[datesToBookList.length - 1].am = false;

    try {
      await bookHolidayV2({holidayBookingList: datesToBookList, originalDates: originalDates});
      window.location.href = `/holidays/${userId}/success/${fromDate.format(DATE_FORMAT)}/${toDate.format(DATE_FORMAT)}`;
    } catch (e: any) {
      try {
        thunkAPI.dispatch(failureNotification(JSON.parse(e.message).message));
      } catch (e: any) {
        thunkAPI.dispatch(failureNotification('Failed to save holiday booking'));
      }
    }
  }
)

export const deleteHolidayBooking: any = createAsyncThunk(
  'holidaysV2/deleteHolidayBooking',
  async (holidayRequest: {fromDate?: Moment, fromDatePeriod: Period, toDate?: Moment, toDatePeriod: Period, userId?: string}, thunkAPI) => {
    const {fromDate, fromDatePeriod, toDate, toDatePeriod, userId} = holidayRequest;
    if (!fromDate) {
      thunkAPI.dispatch(warningNotification('Start date required'));
      TrackJS?.track('Missing start date for holiday booking');
      return;
    }
    if (!toDate) {
      thunkAPI.dispatch(warningNotification('End date required'));
      TrackJS?.track('Missing end date for holiday booking');
      return;
    }
    if (!userId) {
      thunkAPI.dispatch(warningNotification('Failed to find user'));
      TrackJS?.track('Failed to find user to book holiday');
      return;
    }

    const dates = getListOfDaysBetweenInclusive(fromDate, toDate);

    // Default all to AllDay, then update first and last after
    let datesToBookList = dates.map(date => new HolidayBookingRequest(date, userId, Period.AllDay));
    if (fromDatePeriod === Period.AM) datesToBookList[0].pm = false;
    else if (fromDatePeriod === Period.PM) datesToBookList[0].am = false;

    if (toDatePeriod === Period.AM) datesToBookList[datesToBookList.length - 1].pm = false;
    else if (toDatePeriod === Period.PM) datesToBookList[datesToBookList.length - 1].am = false;

    await deleteHolidayV2({holidayBookingList: datesToBookList});
    window.location.href = '/holidays?v=user';
  }
)

export const loadUsersHolidays: any = createAsyncThunk(
  'holidaysV2/loadUsersHolidays',
  async (params: {userId: string, holidayYear: Moment}, thunkAPI) => {
    const calendarDate = (thunkAPI.getState() as AppState).dashboard.calendarDate;
    const config = (thunkAPI.getState() as AppState).config.configEntity;
    const holidayStartDate = config.getHolidayYearStartDate(!!params.holidayYear ? params.holidayYear : calendarDate);
    const response = await fetchUsersHolidayInfoV2(holidayStartDate.format(DATE_FORMAT), params.userId);
    return {
      usersFirstName: response.firstName,
      usersLastName: response.lastName,
      usersTeamName: response.teamName,
      usersUserId: response.userId,
      usersHolidayBookings: response.holidayBookings,
      usersHolidayAllowance: response.holidayAllowance,
      usersHolidaysTaken: response.holidaysTaken,
      usersHolidayGroupings: response.holidayGroupings,
      companyHolidays: response.companyHolidays,
    }
  }
);

export const loadTeamHolidays: any = createAsyncThunk(
  'holidaysV2/loadTeamHolidays',
  async (params: {teamIds?: string[], workGroupIds?: number[], date?: Moment}, thunkAPI) => {
    const date = params?.date ?? selectCalendarDate(thunkAPI.getState() as AppState);
    if (!date) {
      throw new Error('Calendar date not set');
    }

    const workGroupIds = params?.workGroupIds ?? [];
    const teamIds = params?.teamIds ?? [];

    const appState = (thunkAPI.getState() as AppState);
    const selectedTeamIds = appState.holidaysV2?.selectedTeamIds ?? [];
    const selectedWorkGroupIds = appState.holidaysV2?.selectedWorkGroupIds ?? [];

    if (workGroupIds.length > 0 || teamIds.length > 0) {
      thunkAPI.dispatch(setSelectedGroupIds({teamIds: teamIds, workGroupIds: workGroupIds}))
      return await getTeamHolidays(teamIds, workGroupIds, date);
    } else if (selectedTeamIds.length > 0 || selectedWorkGroupIds.length > 0) {
      return await getTeamHolidays(selectedTeamIds,  selectedWorkGroupIds, date);
    } else {
      thunkAPI.dispatch(failureNotification('No team selected'));
      throw new Error('No team id selected')
    }
  }
);


const holidaysV2Slice: any = createSlice({
  name: 'holidaysV2',
  initialState: initialHolidaysV2State,
  reducers: {
    setSelectedTeamIds: (state, action) => ({...state, selectedTeamIds: action.payload, selectedWorkGroupIds: []}),
    setSelectedWorkgroupIds: (state, action) => ({...state, selectedTeamIds: [], selectedWorkGroupIds: action.payload}),
    setSelectedGroupIds: (state, action) => ({
      ...state,
      selectedTeamIds: action.payload.teamIds,
      selectedWorkGroupIds: action.payload.workGroupIds,
    }),
  },
  extraReducers: {
    [loadUsersHolidays.pending]: (state) => ({
      ...state,
      usersHolidayBookings: [],
      usersHolidayGroupings: [],
      companyHolidays: [],
      usersHolidayAllowance: 0,
      usersHolidaysTaken: 0,
      loading: true,
    }),
    [loadUsersHolidays.reject]: (state) => ({...state, loading: false, }),
    [loadUsersHolidays.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false,
    }),

    [deleteHolidayBooking.pending]: (state) => ({...state, loading: true, }),
    [deleteHolidayBooking.reject]: (state) => ({...state, loading: false, }),
    [deleteHolidayBooking.fulfilled]: (state) => ({...state, loading: false, }),

    [bookHoliday.pending]: (state) => ({...state, loading: true, }),
    [bookHoliday.reject]: (state) => ({...state, loading: false, }),
    [bookHoliday.fulfilled]: (state) => ({...state, loading: false, }),

    [loadTeamHolidays.pending]: (state) => ({...state, loading: true, teamHolidays: []}),
    [loadTeamHolidays.reject]: (state) => ({...state, loading: false, teamHolidays: []}),
    [loadTeamHolidays.fulfilled]: (state, action) => ({...state, loading: false, teamHolidays: action.payload}),
  }
});

export const {
  setSelectedTeamIds, setSelectedWorkgroupIds, setSelectedGroupIds,
} = holidaysV2Slice.actions;
export default holidaysV2Slice.reducer;

// Selectors
export const selectUsersBookedHolidays = (state: AppState) =>  state.holidaysV2.usersHolidayBookings;
export const selectCompanyHolidays = (state: AppState) =>  state.holidaysV2.companyHolidays;
export const selectUsersHolidayGroupings = (state: AppState) => {
  return state.holidaysV2.usersHolidayGroupings
};
export const selectUsersUpcomingHolidayGroupings = (state: AppState) => {
  const upcoming = (state.holidaysV2.usersHolidayGroupings ?? []).filter(grouping => !moment(grouping.toDate).isBefore(moment().subtract(1, 'days')))
  return upcoming.sort((a, b) => a.fromDate < b.fromDate ? -1 : 1)
}
export const selectUsersHolidayInfo = (state: AppState) =>  ({
  daysTaken: state.holidaysV2.usersHolidaysTaken, daysLeft: state.holidaysV2.usersHolidayAllowance - state.holidaysV2.usersHolidaysTaken
});
export const selectMonthlyHolidayView = (state: AppState) =>  state.holidaysV2.teamHolidays;
export const selectIsLoading = (state: AppState) => state.holidaysV2.loading;
export const selectHolidayUserInfo = (state: AppState) => ({
  firstName: state.holidaysV2.usersFirstName,
  lastName: state.holidaysV2.usersLastName,
  teamName: state.holidaysV2.usersTeamName,
  userId: state.holidaysV2.usersUserId,
})
