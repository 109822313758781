import React from 'react';
import { OutlineButton } from "../atoms/buttons/OutlineButton";
import { useDispatch } from "react-redux";
import { setCalendarDate } from "../../../store/ducks/dashboard.duck";
import moment from "moment";

export function TodayButton(props: Props) {
  const disptach = useDispatch();
  const onClick = () => {
    disptach(setCalendarDate(moment()));
  }
  return (
    <OutlineButton text={"button.today"}
                   style={{...props?.style ?? {}}}
                   click={onClick} />
  )
}

interface Props {
  style?: any;
}
